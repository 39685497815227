import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import './i18n';

const LanguageInit = props => {
  
  useEffect(() => {
    const { i18n } = props;

    let language = localStorage.getItem('i18nextLng');

    if (language !== 'es' && language !== 'en') {
      localStorage.setItem('i18nextLng', 'es');
      language = 'es';
    }

    i18n.options.backend.loadPath = `${
      process.env.REACT_APP_BUCKECT_LANGUAGE_URL
    }/locales/{{lng}}/{{ns}}.json`;

    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [props]);

  return '';
};

export default withTranslation()(LanguageInit);
