import React, { useState, useEffect, Suspense } from "react";
import Viamobi from "@viamericas/viamobi-component";
import Cookies from "universal-cookie";
import i18n from "./i18n";
import { withTranslation } from "react-i18next";
// import getToken from "./rules/viamobiRules";

const viamericasLogo = require("./assets/images/Viamericas_Logo.png");
const unknowPhoto = require("./assets/images/unknownPhoto.png");
const pdfDefaultPhoto = require("./assets/images/pdfDefault.png");

const cookies = new Cookies();

function App({
  optionQS,
  agencyQS,
  orderQS,
  amountQS,
  language,
  idSenderGlobal,
  nameSender,
  iddoc,
  env,
  region
}) {
  const [idBranch, setIdBranch] = useState(agencyQS || "");
  const [order] = useState(orderQS || "");
  const [amount] = useState(amountQS || " ");
  const [phone, setPhone] = useState("");
  const [activeProcess, setActiveProcess] = useState([]);
  const [token] = useState(process.env.REACT_APP_TOKEN_PASS);
  const [environment] = useState(env || "A" );
  const [r] = useState(region || "" );
  let url = process.env.REACT_APP_URL_VIACHEK_MOBILE.replace("{NAME_REGION}",r );


  if (language !== i18n.language) {
    i18n.changeLanguage(language);
  }

  const setIdBranchData = (data) => {
    setIdBranch(data);
  };

  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  useEffect(() => {
    setPhone(cookies.get("phoneViaMobi"));
  }, []);

  useEffect(() => {
    if (!agencyQS) {
      setIdBranch(cookies.get("agencyViaMobi"));
    }

    if (!optionQS) {
      setActiveProcess(["ID", "PD" /* , 'OF' */]);
    } else {
      setActiveProcess(optionQS.toUpperCase().split(","));
    }
  }, [agencyQS, optionQS]);

  useEffect(() => {
    if (idBranch?.length === 6) {
      cookies.set("agencyViaMobi", idBranch, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
        sameSite: true,
      });
    }
  }, [idBranch]);

  useEffect(() => {
    if (phone?.length === 10 || !phone) {
      cookies.set("phoneViaMobi", phone, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365,
        sameSite: true,
      });
    }
  }, [phone]);

  /* 

  useEffect(() => {
    getToken()
      .then((data) => {
        setToken(data);
      })
      .catch((e) => {
        console.error("ERROR BUSCANDO TOKEN", e);
      });
  }, []);

  */ 

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Viamobi
        idBranch={idBranch}
        setIdBranch={setIdBranchData}
        orderParam={order}
        amountParam={amount}
        phone={phone}
        setPhone={setPhone}
        idSenderGlobal={idSenderGlobal}
        nameSender={nameSender}
        iddoc={iddoc}
        viamericasLogo={viamericasLogo}
        unknowPhoto={unknowPhoto}
        pdfDefaultPhoto={pdfDefaultPhoto}
        isMovile={isMobile()}
        activeProcess={activeProcess}
        token={token}
        url={url}
        env={ (environment === "beta" ? "B": ( environment === "prod" ? "P":"A") )}
      />
    </Suspense>
  );
}

export default withTranslation()(App);
